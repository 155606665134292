import React, { useState, useEffect, useRef } from 'react';
import { ImageElm } from "../../Elements/Image/ImageElm";
import { Button } from "../../Elements/Button/Button";
import css from "./TabsLayout.module.scss";
import { BackgroundColorOptions } from "./../../Elements/BackgroundColorOptions/BackgroundColorOptions.jsx";

export const TabsLayout = ({ fullWidth, tabRepeater, backgroundColor }) => {

    const [currentTab, setCurrentTab] = useState(tabRepeater?.tab[0]?.id);
    const [isOverflowing, setIsOverflowing] = useState(false);

    const backgroundOptionColor = BackgroundColorOptions({
    optionColor: backgroundColor?.colors,
    });

    if (tabRepeater === undefined) {
        console.error(
            "Content is undefined in TabsLayout, returning nothing to UI. Try adding content to the block."
        );
        return null;
    }

    const listRef = useRef(null);

    const checkOverflow = () => {
        if (listRef.current) {
            const isScroll = listRef.current.scrollWidth > listRef.current.clientWidth;
            setIsOverflowing(isScroll);
        }
    };

    useEffect(() => {
      checkOverflow();
      window.addEventListener('resize', checkOverflow);
      return () => window.removeEventListener('resize', checkOverflow);
    }, []);


    const handleTabClick = (e) => {
        setCurrentTab(e.target.id);
    };

    const TabsRepeater = ({ repeater }) => {
        return (
        <>
            {repeater?.map((item, index) => (
            <div key={index}>
                {currentTab == `${item?.id}` && ( // If currentTab is equal to the id of the tab, show the content
                <div className={css.tabContentWrapper}>
                    <div className={css.tabLeft}>
                    <div dangerouslySetInnerHTML={{ __html: item?.html }} />
                    <Button extraCss="extraMarginSmall" {...item?.button} />
                    </div>
                    <div className={css.tabRight}>
                    <ImageElm input={item?.image?.data} />
                    </div>
                </div>
                )}
            </div>
            ))}
        </>
        );
    };


    return (
    <div className={`
        ${fullWidth?.fullWidth === true ? "full" : "wrapped"}
        ${backgroundOptionColor}
    `}>
        <div className={`
            ${fullWidth?.fullWidth === true ? "ifFullWidthContentWrapper" : ""}
            ${css.TabsLayoutWrapper}
        `}
        >

            <div className={css.tabs}>
                <div className={`${isOverflowing ? css.overlay : ''}`} />

                <div
                    className={`${css.tabsFlex}`}
                    style={{justifyContent: tabRepeater?.tab?.length > 4 && 'space-between'}}
                    ref={listRef}
                    id="target"
                >
                {tabRepeater?.tab?.map((tab, i) => (
                    <a
                        key={i}
                        id={tab?.id}
                        className={currentTab == tab?.id ? css.active : ""}
                        onClick={(e) => {
                          e.preventDefault();
                          handleTabClick(e);
                        }}
                        href="#"
                    >
                        {tab?.title}
                    </a>
                ))}
                </div>
                <div className={css.border} />
            </div>

            <div className={`wrapped ${fullWidth?.fullWidth === true ? "full" : ""}`}>
                <TabsRepeater repeater={tabRepeater?.tab} />
            </div>
        </div>
    </div>
    );
}
